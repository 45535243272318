<template>
  <div class="dropdown">
    <ion-button
      id="trigger-button"
      @click="showPopover = !showPopover"
      style="max-width: 100%"
    >
      <small class="text-ellipsis">{{ dropdownLabel }}</small>
    </ion-button>
    <ion-popover :is-open="showPopover">
      <ion-content>
        <ion-list v-if="leagues">
          <ion-item
            :button="true"
            :detail="false"
            v-for="league of leagues"
            :key="league.id"
            @click="changeLeague(league)"
          >
            <ion-label>{{ league.name }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-popover>
  </div>
</template>

<script>
import leaguesModule from "../../modules/leagues";
import axios from "axios";
const { currentLeagueId } = leaguesModule;
import { IonButton, IonPopover } from "@ionic/vue";
export default {
  data: () => ({
    leagues: null,
    currentLeague: null,
    currentLeagueId: null,
    showPopover: false,
  }),
  components: {
    IonButton,
    IonPopover,
  },
  computed: {
    dropdownLabel() {
      if (this.currentLeague) {
        return this.currentLeague.name;
      }
      return "Select a league";
    },
  },
  watch: {
    currentLeagueId() {
      if (this.currentLeagueId && this.leagues) {
        this.currentLeague = Array.from(this.leagues).find(
          (l) => l.id == this.currentLeagueId
        );
      }
    },
  },
  methods: {
    async changeLeague(league) {
      window.location.href = "/league/" + league.id + "/home";
      this.showPopover = false;
    },
  },
  async mounted() {
    this.leagues = (await axios.get("/leagues")).data.leagues.data;
    this.currentLeagueId = currentLeagueId;
  },
};
</script>

<style scoped>
.text-ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
</style>
