import axios from 'axios';

function configureAxios(auth) {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    axios.interceptors.response.use(function (response) {
        return response;
    }, (err) => {
        console.log('Axios error', err);
        if (err.request.status === 500) {
            if (!auth.isReady) {
                console.log('Logging out user, due to server error before auth is settled.');
                try {
                    auth.logout({
                        redirect: { name: 'login' }
                    });
                } catch (e) {
                    console.warn('Force logout failed.', e);
                }
            }
        }
        throw err;
    });
    return axios;
}

export default configureAxios;