import { createRouter, createWebHistory } from "@ionic/vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/leagues",
  },
  {
    path: "/league",
    redirect: "/leagues",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../pages/LoginPage.vue"),
    meta: {
      auth: false,
    },
  },
  {
    path: "/leagues",
    component: () => import("../pages/WelcomePage.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/league/:league_id/leaderboard",
    component: () => import("../pages/LeagueLeaderboardPage.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/league/:league_id/history",
    component: () => import("../pages/LeagueHistoryPage.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/league/:league_id/history/:season_id",
    component: () => import("../pages/LeagueHistoryPage.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/league/:league_id/stats",
    component: () => import("../pages/LeagueStatsPage.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/league/:league_id/stats/:season_id",
    component: () => import("../pages/LeagueStatsPage.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/league/:league_id/home",
    name: "play",
    component: () => import("../pages/LeagueHomePage.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/league/:league_id",
    redirect: "/league/:league_id/home",
  },
  {
    path: "/account",
    component: () => import("../pages/AccountPage.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/todo",
    name: "todo",
    component: () => import("../pages/PageToDo.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../pages/PageNotFound.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
