import { ref } from 'vue'

const userData = ref(null);
const error = ref(null);

const setUserData = (data) => {
    userData.value = data;
}

const logout = () => {
    userData.value = null;
}

export default { setUserData, userData, error, logout };
