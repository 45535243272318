<template>
  <ion-page v-if="$auth.ready()" id="page-container">
    <ion-header>
      <div
        style="background-color: #164b63"
        class="text-center"
        @click="$router.push('/')"
      >
        <img
          src="/assets/TeepsterLogobeta.png"
          style="height: 8vh"
          class="py-2"
        />
      </div>
      <div class="text-center">
        <img src="/assets/ad-placeholder.png" style="max-height: 13vh" />
      </div>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            :default-href="pageDefaultBackLink"
          ></ion-back-button>
        </ion-buttons>
        <div class="d-flex">
          <ion-title>{{ pageTitle }}</ion-title>
          <dropdown style="max-width: 60vw" class="pe-3" />
        </div>
        <ion-buttons slot="end">
          <slot name="actions-end"></slot>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <notifications position="bottom center" />
    <ion-router-outlet></ion-router-outlet>
    <ion-content class="pt-1">
      <!-- <ad-space /> -->
      <slot />
    </ion-content>
    <ion-tab-bar slot="bottom" v-if="isLoggedIn">
      <ion-tab-button tab="leagues" href="/leagues">
        <ion-icon :icon="triangle" />
        <ion-label>Leagues</ion-label>
      </ion-tab-button>

      <ion-tab-button
        v-if="currentLeagueId"
        tab="play"
        :href="`/league/${currentLeagueId}/home`"
      >
        <ion-icon :icon="play" />
        <ion-label>Play</ion-label>
      </ion-tab-button>

      <ion-tab-button
        v-if="currentLeagueId"
        tab="leaderboard"
        :href="`/league/${currentLeagueId}/leaderboard`"
      >
        <ion-icon :icon="podium" />
        <ion-label>Leaderboard</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="account" href="/account">
        <ion-icon :icon="ellipse" />
        <ion-label>Account</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-page>
</template>

<script>
import {
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonIcon,
  IonPage,
  IonTitle,
  IonHeader,
  IonToolbar,
  IonContent,
  IonBackButton,
  IonButtons,
  IonRouterOutlet,
} from "@ionic/vue";
import { ellipse, square, triangle, podium, play } from "ionicons/icons";

import leagueModule from "../modules/leagues";
import session from "../modules/session";
import Dropdown from "../components/leagues/LeagueDropdown.vue";

export default {
  name: "Tabs",
  props: ["pageTitle", "pageDefaultBackLink"],
  components: {
    IonLabel,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonTitle,
    IonHeader,
    IonToolbar,
    IonContent,
    IonBackButton,
    IonButtons,
    IonRouterOutlet,
    Dropdown,
  },
  computed: {
    currentLeagueId() {
      return leagueModule.currentLeagueId.value;
    },
    isLoggedIn() {
      return this.$auth.check();
      // return session.userData.value && session.userData.value.id;
    },
    userData() {
      return session.userData;
    },
  },
  setup() {
    return {
      ellipse,
      square,
      triangle,
      podium,
      play,
    };
  },
};
</script>

<style scoped>
.vue-notification-group {
  bottom: 4rem !important;
}
</style>
