import axios from "axios";
import { ref } from "vue";

const leagues = ref(null);
const currentLeagueId = ref(null);
const error = ref(null);

const load = async () => {
  const response = await axios.get("leagues").catch((e) => {
    error.value = e;
  });
  leagues.value = response.data.leagues;
};

const setCurrentLeague = (leagueId) => {
  if (leagueId == ":league_id") {
    leagueId = null;
  }
  currentLeagueId.value = leagueId;
  return leagueId;
};

export default {
  load,
  leagues,
  currentLeagueId,
  setCurrentLeague,
  error,
};
